import React, { Component } from 'react';
import logoMin from './logoMin.svg';
import logo from './logo.svg';
import powered from './powered.png';
import github from './github.png';
import medium from './medium.svg';
import nettbil from './nettbil.svg';
import biosort from './biosort.png';
import apn from './apn.png';
import datadog from './datadog.png';
import confluent from './confluent.png';
import './App.css';
import "/node_modules/flag-icons/css/flag-icons.min.css";

const images = new Map([
  ['github', github],
  ['medium', medium],
  ['nettbil', nettbil],
  ['biosort', biosort],
  ['apn', apn],
  ['datadog', datadog],
  ['confluent', confluent],
]);

export const DATA = {
  "no": {
    "header": {
      "links": [
        {
          "name": "blog",
          "url": "https://blog.flowlab.no",
          "imgKey": "medium"
        },
        {
          "name": "github",
          "url": "https://github.com/orgs/flow-lab",
          "imgKey": "github"
        }
      ]
    },
    "mainText": {
      "header": "DevSecOps Accelerator og Cloud Consulting.",
      "content": "Programvareutvikling og rådgivningstjenester til små og store bedrifter."
    },
    "about": {
      "content": "Flow Lab har levert programvareutviklings- og rådgivningstjenester til store og små bedrifter i det skandinaviske markedet i over seks år. Så uansett om du ønsker å forbedre infrastrukturen for applikasjonen din, begynne å bruke Kubernetes, automatisere prosessene eller begynne å bruke AWS plattformen vår, er Flow Lab her for å hjelpe deg med å oppnå målene dine og hjelpe bedriften din."
    },
    "products": {
      "header": "Tjenester og produkter",
      "content": [{
        "name": "Consulting",
        "content": "Vi forstår at det å bygge og vedlikeholde applikasjoner kan være en vanskelig oppgave, derfor tilbyr vi rådgivningstjenester for å hjelpe deg hele veien. Flow Lab kan veilede deg gjennom prosessen med å designe og utvikle applikasjonene. Vi tilbyr også hjelp med sikkerhet for å sikre at din bedrifts- og kundedata er trygge."
      },
      {
        "name": "Flow Platform",
        "content": "Flow-plattformen gjør det enkelt for deg å bygge og administrere applikasjonene dine på Google Cloud Platform GCP eller Amazon Web Services AWS ved å bruke mikrotjenester-arkitektur. Vi bruker Terraform for å sikre at infrastrukturen er definert som kode, noe som gjør det enkelt å skalere og vedlikeholde. Plattformen vår gir deg alle verktøyene du trenger for å distribuere, skalere og overvåke mikrotjenestene dine, slik at du kan fokusere på å levere verdi til kundene dine. Flow Lab er alltid her for å assistere deg og sikre at applikasjonen din kjører jevnt, effektivt og med langvarig støtte."
      },
      {
        "name": "Flow MLOps",
        "content": "MLOps fra Flow Lab hjelper deg å administrere og vedlikeholde maskinlæringsmodeller i produksjonen. Vi tilbyr et utgangspunkt for å bygge, distribuere og vedlikeholde modellene dine, med fokus på ytelse, skalerbarhet og sikkerhet. Tjenesten vår inkluderer støtte for databehandling, modellutvikling, distribusjon og overvåking."
      },
      {
        "name": "Cyber Security",
        "content": "Hos Flow Lab forstår vi viktigheten av å holde webapplikasjonene og APIene dine sikre. Derfor tilbyr vi ekspert rådgivningstjenester innen sikkerhet for å sikre at applikasjonene dine er beskyttet mot angrep og uautorisert tilgang. Vårt team kan hjelpe deg med å konfigurere og overvåke nettverkstrafikken, samt installere og sette opp WAF-brannmurer for ekstra sikkerhet. Enten du trenger hjelp til å sikre eksisterende applikasjoner eller ønsker veiledning i å bygge sikre applikasjoner fra bunnen av, så er vi her for å hjelpe."
      }]
    },
    "clients": {
      "header": "Kunder",
      "content": [{
        "name": "nettbil",
        "url": "https://www.nettbil.no",
        "imgKey": "nettbil"
      }, {
        "name": "biosort",
        "url": "https://www.biosort.no",
        "imgKey": "biosort"
      }]
    },
    "partners": {
      "header": "Partnere",
      "content": [
        {
          "name": "Confluent",
          "url": "https://partners.confluent.io/English/directory/partner/1247198/flow-lab-as",
          "imgKey": "confluent"
        }, {
          "name": "Datadog consulting",
          "url": "https://partners.datadoghq.com/s/PartnerDirectoryDetail?pid=0010e00001PEs5GAAT",
          "imgKey": "datadog"
        }]
    },
    "contact": {
      "header": "Ta kontakt",
      "email": "kontakt@flowlab.no",
      "phoneNumber": "+ 47 450 64 756",
      "companyName": "Flow Lab AS - Org. nr. 917 482 209",
      "address": "Rebel, Universitetsgata 2, 0164 Oslo"
    },
    "poweredBy": {
      "src": "/images/powered-by-aws.png",
      "alt": "Powered by AWS Cloud Computing",
      "href": "https://aws.amazon.com/what-is-cloud-computing"
    }
  },
  "en": {
    "header": {
      "links": [
        {
          "name": "blog",
          "url": "https://blog.flowlab.no",
          "imgKey": "medium"
        },
        {
          "name": "github",
          "url": "https://github.com/orgs/flow-lab",
          "imgKey": "github"
        }
      ]
    },
    "mainText": {
      "header": "DevSecOps Accelerator and Cloud Consulting.",
      "content": "Software development and consultancy solutions to businesses in the Scandinavian market."
    },
    "about": {
      "content": "Flow Lab has been providing software development and consultancy solutions to startups in the Scandinavian market for over six years. So whether you're looking to improve your application's infrastructure, speed up deployment with Kubernetes, automate your processes, or start using a platform based on AWS, Flow Lab is here to help you achieve your goals and drive success for your business."
    },
    "products": {
      "header": "Products and services",
      "content": [{
        "name": "Consulting",
        "content": "We understand that building and maintaining applications can be a daunting task, that's why we offer consulting services to help you every step of the way. Flow Lab can guide you through the process of designing and developing your application on the cloud. We also offer help with security measures to ensure that your business and customer data is safe and secure."
      },
      {
        "name": "Flow Platform",
        "content": "The Flow Platform makes it easy for you to build and manage your applications on Google Cloud Platform GCP or Amazon Web Services AWS using microservices architecture. We use Terraform to ensure that infrastructure is defined as code, making it easy to scale and maintain. Our platform provides all the tools you need to deploy, scale and monitor your microservices, so you can focus on delivering value to your customers. And our team at Flow Lab is always here to assist you and ensure your application runs smoothly, efficiently and with long-term support."
      },
      {
        "name": "Flow MLOps",
        "content": "MLOps from Flow Lab helps manage and maintain machine learning models in production. We provide a starting point for building, deploying, and maintaining your models, with a focus on performance, scalability and security. Our service includes support for data preparation, model development, deployment and monitoring."
      }, {
        "name": "Cyber Security",
        "content": "At Flow Lab, we understand the importance of keeping your web applications and APIs secure. That's why we offer expert security advisory services to ensure your applications are protected against attacks and unauthorized access. Our team can help you configure and monitor your network traffic, as well as install and set up WAF firewalls for added security. Whether you need help securing your existing applications or would like guidance in building secure applications from scratch, we're here to help."
      }]
    },
    "clients": {
      "header": "Clients",
      "content": [{
        "name": "nettbil",
        "url": "https://www.nettbil.no",
        "imgKey": "nettbil"
      }, {
        "name": "biosort",
        "url": "https://www.biosort.no",
        "imgKey": "biosort"
      }]
    },
    "partners": {
      "header": "Partners",
      "content": [
        {
          "name": "Confluent",
          "url": "https://partners.confluent.io/English/directory/partner/1247198/flow-lab-as",
          "imgKey": "confluent"
        }, {
          "name": "Datadog consulting",
          "url": "https://partners.datadoghq.com/s/PartnerDirectoryDetail?pid=0010e00001PEs5GAAT",
          "imgKey": "datadog"
        }]
    },
    "contact": {
      "header": "Get in touch",
      "email": "kontakt@flowlab.no",
      "phoneNumber": "+ 47 450 64 756",
      "companyName": "Flow Lab AS - Org. nr. 917 482 209",
      "address": "Rebel, Universitetsgata 2, 0164 Oslo"
    },
    "poweredBy": {
      "src": "/images/powered-by-aws.png",
      "alt": "Powered by Amazon Web Services AWS",
      "href": "https://aws.amazon.com/what-is-cloud-computing"
    }
  },
};

class LanguageButton extends Component {
  constructor(props) {
    super(props);
    this.state = { languageCode: "en" };
  }

  // add click handler
  click = () => {
    if (this.state.languageCode === 'no') {
      this.setState({ languageCode: 'en' });
    } else if (this.state.languageCode === 'en') {
      this.setState({ languageCode: 'no' });
    }
    if (this.props.handlerClick) {
      this.props.handlerClick(this.state.languageCode);
    }
  }

  getFlag = () => {
    if (this.state.languageCode === 'no') {
      return <span className="fi fi-no"></span>;
    } else if (this.state.languageCode === 'en') {
      return <span className="fi fi-gb"></span>;
    }
  }

  render() {
    return <button className="language_btn" onClick={this.click}>
      {this.getFlag()}
    </button>
  }
}

class Header extends Component {
  render() {
    let links = this.props.data.header.links.map((key, idx) => {
      if (key.imgKey) {
        const img = images.get(key.imgKey);
        return <a className="header__link" key={idx} href={key.url}>
          <span className="header__text">{key.name}</span>
          <img src={img} className="header__img" alt={img} />
        </a>;
      }
      return <a className="header__link" key={idx} href={key.url}>{key.name}</a>;
    });
    if (this.props.data.linksVisible === true) {
      return <div className="header">
        <div className="header__logo">
          <img src={logoMin} alt="logo" />
        </div>
        {this.props.data.linksVisible ? (<div className="header__links"><LanguageButton handlerClick={this.props.languageHandler} />{links}</div>) : null}

      </div>
    } else {
      return null
    }
  }
}

class Main extends Component {
  render() {
    return <div className="main">
      <img src={logo} className="main__logo" alt="logo" />
    </div>
  }
}

class MainText extends Component {
  render() {
    return <div className="main__text">
      <h1>{this.props.data.header}</h1>
      <h2>{this.props.data.content}</h2>
    </div>
  }
}

class About extends Component {
  render() {
    return <div className="main__about">
      <p>{this.props.data.content}</p>
    </div>
  }
}

class Contact extends Component {
  render() {
    return <div className="address">
      <h2>{this.props.data.header}</h2>
      <a href={"mailto:" + this.props.data.email}>{this.props.data.email}</a>
      <p>{this.props.data.companyName}<br />{this.props.data.address}</p>
      <span>{this.props.data.phoneNumber}</span>
    </div>
  }
}

class Products extends Component {
  render() {
    let products = this.props.data.content.map((key, idx) => {
      return <div className="products__item" key={idx}>
        <h3>{key.name}</h3>
        <p>{key.content}</p>
      </div>;
    });

    return <div className="clients">
      <h2>{this.props.data.header}</h2>
      <div>{products}</div>
    </div>
  }
}

class Clients extends Component {
  render() {
    let links = this.props.data.content.map((key, idx) => {
      const img = images.get(key.imgKey);
      return <a className="clients__link" key={idx} href={key.url}>
        <img src={img} className="clients__logo" id={key.name} alt={img} />
      </a>;
    });

    return <div className="clients">
      <h2>{this.props.data.header}</h2>
      <div className="clients__logo">{links}</div>
    </div>
  }
}

class Partners extends Component {
  render() {
    let logos = this.props.data.content.map((key, idx) => {
      const img = images.get(key.imgKey);
      return <a className="partners__link" key={idx} href={key.url}>
        <img src={img} className="partners__logo" alt={img} />
      </a>;
    });

    return <div className="partners">
      <h2>{this.props.data.header}</h2>
      <div className="partners__logo">{logos}</div>
    </div>
  }
}

class PoweredByAWS extends Component {
  render() {
    return <a href={this.props.data.href}>
      <img src={powered} className="poweredby__img" alt="Powered by Amazon Web Services AWS" />
    </a>
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: 'no'
    };
  }

  languageHandler = (code) => {
    this.setState({
      language: code
    });
  }

  render() {
    return <div className="App">
      <div className="grid">
        <div className="grid__item grid__item--blue">
          <Header data={{
            header: this.state.language === "no" ? this.props.data.no.header : this.props.data.en.header,
            linksVisible: true
          }} languageHandler={this.languageHandler} />
        </div>

        <HomeView data={this.state.language === "no" ? this.props.data.no : this.props.data.en} />

        <div className="grid__item">
          <PoweredByAWS data={this.state.language === "no" ? this.props.data.no.poweredBy : this.props.data.en.poweredBy} />
        </div>
      </div>
    </div>
  }
}

class HomeView extends Component {
  render() {
    return <div>
      <div className="grid__item">
        <Main data={this.props.data.main} />
      </div>
      <div className="grid__item grid__item--blue">
        <MainText data={this.props.data.mainText} />
        <About data={this.props.data.about} />
        <Products data={this.props.data.products} />
        <Clients data={this.props.data.clients} />
        <Partners data={this.props.data.partners} />
        <Contact data={this.props.data.contact} />
      </div>
    </div>
  }
}

export default App;